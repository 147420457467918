<template>
  <div>
    <van-nav-bar
      class="van_nav_bar_view"
      style="background: #18b271"
      :fixed="true"
      left-arrow
    >
      <template #left>
        <img
          @click="goHoem"
          style="width: 18px; height: 18px"
          src="../../../assets/left_return_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: white; font-size: 18px">支付成功</span>
      </template>
    </van-nav-bar>
    <div>
      <div class="title_bg_view max_width">
        <div class="title_view"></div>
        <!--支付成功状态图标-->
        <div
          class=""
          style="
            max-width: 540px;
            height: 230px;
            width: 80%;
            position: absolute;
            top: 100px;
          "
        >
          <div class="mb_view" v-show="isSuccess">
            <img src="../../../assets/pay_success_bg.png" alt="" />
            <span>支付成功</span>
          </div>
          <div class="mb_view" v-show="!isSuccess">
            <img src="../../../assets/pay_error_bg.png" alt="" />
            <div
              style="display: flex; flex-direction: row; align-items: center"
            >
              还剩<van-count-down
                style="color: #df3436"
                :time="time"
              />自动关闭订单
            </div>
          </div>
        </div>
      </div>
      <!--支付成功按钮-->
      <div class="button_vieww" v-show="isSuccess">
        <van-button @click="goHome">再逛逛</van-button>
        <van-button @click="goOrder(2)">查看订单</van-button>
      </div>
      <!--支付失败按钮-->
      <div class="button_vieww" v-show="!isSuccess">
        <van-button>取消订单</van-button>
        <van-button>立即支付</van-button>
      </div>
    </div>

    <div class="bottom_goods_view">
      <img src="../../../assets/tuijian_bg.png" alt="" />
      <van-list
        class="list_view"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell
          class="list_item_view"
          v-for="(tjItem, tjIndex) in recommendList"
          :key="tjIndex"
          @click="toGoodsDetail(tjItem)"
        >
          <van-image
            class="img_view"
            :src="imgurl + tjItem.image"
            :alt="tjItem.name"
          ></van-image>
          <span
            style="
              font-size: 13px;
              color: #3f3f3f;
              margin-left: 10px;
              display: block;
            "
            >{{ tjItem.name }}</span
          >
          <!-- <span
            style="
              font-size: 12px;
              color: #999999;
              margin-left: 11px;
              display: block;
            "
            >香甜可口 嫩脆爽口</span
          > -->
          <div
            style="
              display: flex;
              height: 40px;
              flex-direction: row;
              align-items: center;
              margin-left: 10px;
              justify-content: space-between;
            "
          >
            <div class="price_view">
              <span style="color: #df3436; font-size: 12px">₱</span>
              <span style="color: #df3436; font-size: 18px">{{
                tjItem.price
              }}</span>
            </div>
            <img
              style="width: 20px; height: 20px"
              src="../../../assets/add_view_bg.png"
              alt=""
            />
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import { goods_hotGoods, imgApp, recommendSpu } from "@/service/api/index.js";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isSuccess: true,
      time: 30 * 60 * 60 * 1000,
      imgurl: "",
      recommendList: [],
      pageIndex: 1,
      pageSize: 9999,
    };
  },
  mounted() {
    this.imgurl = imgApp;
    // console.log(this.isSuccess)
    // console.log(this.time)
    this.recommendSpu();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    goHome() {
      this.$router.push("/");
    },
    goOrder(e) {
      this.$router.push({
        name: "Order",
        params: {
          tabIndex: 2,
        },
      });
    },
    recommendSpu() {
      recommendSpu({
        spuPoId: this.$route.query.id,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.status == 200) {
          this.recommendList = res.data.list;
        }
      });
    },
    toGoodsDetail(tjItem) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          id: tjItem.id,
        },
      });
    },
    goHoem() {
      this.$router.replace({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 去掉导航栏底部白线问题
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
@media screen and (min-width: 768px) {
  .van_nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .max_width {
    max-width: 540px;
  }

  .title_bg_view {
    max-width: 540px;
  }
}

.title_bg_view {
  width: 100%;
  height: 94px;
  margin-top: 44px;
  background: #18b271;
  display: flex;
  justify-content: center;
  .title_view {
    width: 90%;
    height: 20px;
    margin-top: 33px;
    background: #02a664;
    border-radius: 12px;
    margin-left: 25px;
    margin-right: 25px;
  }
}
.mb_view {
  width: 100%;
  height: 230px;
  background-image: url("../../../assets/bl_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 230px;
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 109px;
    height: 109px;
    margin-bottom: 20px;
  }
  span {
    font-size: 16px;
    color: #3f3f3f;
  }
}
.button_vieww {
  width: 100%;
  margin-top: 200px;
  text-align: center;
  button:first-of-type {
    width: 40%;
    height: 36px;
    background: #ffffff;
    font-size: 13px;
    color: #28bd71;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
  }
  button:last-of-type {
    width: 40%;
    height: 36px;
    color: white;
    background: linear-gradient(90deg, #01b15b 0%, #49d0a2 100%);
    box-shadow: 0px 4px 10px 0px rgba(38, 188, 113, 0.5);
    border-radius: 18px;
    margin-left: 5px;
    border: none;
  }
}
.bottom_goods_view {
  width: 100%;
  background: white;
  margin-top: 40px;
  text-align: center;
  img {
    width: 158px;
    height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .list_view {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 100px;
    .list_item_view {
      width: 48%;
      margin: 3px auto;
      background: white;
      box-shadow: 0px 10px 18px 0px rgba(153, 153, 153, 0.18);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .img_view {
        width: 118.5px;
        height: 118.5px;
        background: yellowgreen;
        margin-left: 5px;
      }
    }
  }
  .list_view::after {
    content: "";
    width: 48%;
  }
}
</style>